import { create } from 'zustand';
import { id as aireStockageId, props as aireStockageProps } from '../features/aire-stockage';
import { id as circuitChampId, props as circuitChampProps } from '../features/circuit-champ';
import { id as lieuDitId, props as lieuDitProps } from '../features/lieu-dit';
import {
  id as materielAgricoleId,
  props as materielAgricoleProps,
} from '../features/materiel-agricole';
import {
  id as meteoId,
  props as meteoProps,
  idSelection as meteoSelectionId,
  propsSelection as meteoSelectionProps,
  idText as meteoTextId,
  propsText as meteoTextProps,
} from '../features/meteo';
import {
  id as parcelleId,
  props as parcelleProps,
  idCircuit as parcelleCircuitId,
  propsCircuit as parcelleCircuitProps,
} from '../features/parcelle';
import { id as pictoId, props as pictoProps } from '../features/picto';
import { id as ravageurId, props as ravageurProps } from '../features/ravageur';
import {
  id as secteurId,
  props as secteurProps,
  idSelection as secteurIdSelection,
  propsSelection as secteurPropsSelection,
  idCineticOrdre as secteurIdCineticOrdre,
  propsCineticOrdre as secteurPropsCineticOrdre,
} from '../features/secteur';
import {
  id as siloId,
  props as siloProps,
  idCircuit as siloCircuitId,
  propsCircuit as siloCircuitProps,
} from '../features/silo';
import {
  id as statistiqueId,
  props as statistiqueProps,
  idSelection as statistiqueSelectionId,
  propsSelection as statistiqueSelectionProps,
  idText as statistiqueTextId,
  propsText as statistiqueTextProps,
} from '../features/statistique';
import {
  id as surfaceId,
  props as surfaceProps,
  idSelection as surfaceSelectionId,
  propsSelection as surfaceSelectionProps,
  idText as surfaceTextId,
  propsText as surfaceTextProps,
} from '../features/surface';
import { id as usineId, props as usineProps } from '../features/usine';
//import {
//  id as zoneContournementId,
//  props as zoneContournementProps,
//} from '../features/zone-contournement';

const useLayerStore = create(() => ({
  activeLayer: null, // OpenLayers layer instance
  selectorOpened: false,
  raster: [
    {
      id: 'Scan25tour_XYZ',
      title: 'Scan25tour',
      type: 'XYZ',
      url:
        'https://go.cristal-cloud.net/' +
        'mapproxy/wmts/scan25tour_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: true,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
    {
      id: 'Hybride_XYZ',
      title: 'Hybride',
      type: 'XYZ',
      url:
        'https://go.cristal-cloud.net/' + 'mapproxy/wmts/hybrid_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: false,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
    {
      id: 'Satellite_XYZ',
      title: 'Satellite',
      type: 'XYZ',
      url: 'https://go.cristal-cloud.net/' + 'mapproxy/wmts/ortho_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: false,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
    {
      id: 'TopoCOL',
      title: 'COL Jour',
      type: 'MapTiler',
      url: 'https://api.maptiler.com/maps/2549fd93-c0e0-4f57-9c18-4bc19de6721a/style.json?key=Jjy6BLH6CcTtpvN1sJ4J',
      visible: false,
      state: 'ADD',
    },
    {
      id: 'TopoCOLNuit',
      title: 'COL Nuit',
      type: 'MapTiler',
      url: 'https://api.maptiler.com/maps/90fd9917-1867-40ee-9d99-87488c20fcb2/style.json?key=Jjy6BLH6CcTtpvN1sJ4J',
      visible: false,
      state: 'ADD',
    },
    {
      id: 'HybrideCOL',
      title: 'COL Hybride',
      type: 'MapTiler',
      url: 'https://api.maptiler.com/maps/29514743-81a9-4d38-9a01-8d5bef9632fc/style.json?key=Jjy6BLH6CcTtpvN1sJ4J',
      visible: false,
      state: 'ADD',
    },
  ],
  vector: [
    { id: secteurId, ...secteurProps, state: 'ADD' },
    { id: secteurIdSelection, ...secteurPropsSelection, state: 'ADD' },
    { id: secteurIdCineticOrdre, ...secteurPropsCineticOrdre, state: 'ADD' },
    { id: lieuDitId, ...lieuDitProps, state: 'ADD' },
    { id: meteoId, ...meteoProps, state: 'ADD' },
    { id: meteoSelectionId, ...meteoSelectionProps, state: 'ADD' },
    { id: meteoTextId, ...meteoTextProps, state: 'ADD' },
    { id: statistiqueId, ...statistiqueProps, state: 'ADD' },
    { id: statistiqueSelectionId, ...statistiqueSelectionProps, state: 'ADD' },
    { id: statistiqueTextId, ...statistiqueTextProps, state: 'ADD' },
    { id: surfaceId, ...surfaceProps, state: 'ADD' },
    { id: surfaceSelectionId, ...surfaceSelectionProps, state: 'ADD' },
    { id: surfaceTextId, ...surfaceTextProps, state: 'ADD' },
    { id: usineId, ...usineProps, state: 'ADD' },
    { id: parcelleCircuitId, ...parcelleCircuitProps, state: 'ADD' },
    { id: parcelleId, ...parcelleProps, state: 'ADD' },
    { id: siloCircuitId, ...siloCircuitProps, state: 'ADD' },
    { id: siloId, ...siloProps, state: 'ADD' },
    { id: aireStockageId, ...aireStockageProps, state: 'ADD' },
    { id: pictoId, ...pictoProps, state: 'ADD' },
    { id: circuitChampId, ...circuitChampProps, state: 'ADD' },
    { id: materielAgricoleId, ...materielAgricoleProps, state: 'ADD' },
    { id: ravageurId, ...ravageurProps, state: 'ADD' },
    //{ id: zoneContournementId, ...zoneContournementProps, state: 'ADD' },
  ],
}));

const setActiveLayer = (layer) =>
  useLayerStore.setState(() => ({
    activeLayer: layer,
  }));

const addRasterLayer = (layer) =>
  useLayerStore.setState((state) => ({
    raster: [...state.raster, { ...layer, id: `${layer.title}_${layer.type}`, state: 'ADD' }],
  }));

const addedRasterLayer = (layer) => {
  useLayerStore.setState((state) => ({
    raster: state.raster.map((l) => (layer.id === l.id ? { ...l, state: 'ADDED' } : l)),
  }));
};

const setRasterLayer = (layer) => {
  const index = useLayerStore.getState().raster.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    raster: state.raster.map((l, i) => (i === index ? { ...l, ...layer, state: 'SET' } : l)),
  }));
};

const removeRasterLayer = (layer) => {
  const index = useLayerStore.getState().raster.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    raster: state.raster.map((l, i) => (i === index ? { ...l, ...layer, state: 'REMOVE' } : l)),
  }));
};

const removedRasterLayer = (layer) =>
  useLayerStore.setState((state) => ({
    raster: state.raster.filter((item) => item.id !== layer.id),
  }));

const addVectorLayer = (layer) =>
  useLayerStore.setState((state) => ({
    vector: [...state.vector, { ...layer, id: `${layer.title}_${layer.type}`, state: 'ADD' }],
  }));

const addedVectorLayer = (layer) => {
  useLayerStore.setState((state) => ({
    vector: state.vector.map((l) => (layer.id === l.id ? { ...l, state: 'ADDED' } : l)),
  }));
};

const setVectorLayer = (layer) => {
  const index = useLayerStore.getState().vector.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    vector: state.vector.map((l, i) => (i === index ? { ...l, ...layer, state: 'SET' } : l)),
  }));
};

const removeVectorLayer = (layer) => {
  const index = useLayerStore.getState().vector.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    vector: state.vector.map((l, i) => (i === index ? { ...l, ...layer, state: 'REMOVE' } : l)),
  }));
};

const removedVectorLayer = (layer) =>
  useLayerStore.setState((state) => ({
    vector: state.vector.filter((item) => item.id !== layer.id),
  }));

const setLayerSelectorOpened = (opened) => {
  useLayerStore.setState(() => ({ selectorOpened: opened }));
};

export {
  addedRasterLayer,
  addedVectorLayer,
  addRasterLayer,
  addVectorLayer,
  removedRasterLayer,
  removedVectorLayer,
  removeRasterLayer,
  removeVectorLayer,
  setActiveLayer,
  setLayerSelectorOpened,
  setRasterLayer,
  setVectorLayer,
  useLayerStore,
};
